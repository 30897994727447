import { SentryLink } from 'apollo-link-sentry'
import 'cross-fetch/polyfill'
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client/core'
import type { ApolloCache, NormalizedCache } from '@apollo/client/core'

import { createUploadLink } from 'apollo-upload-client'
import { v4 as uuidv4 } from 'uuid'
import { Context } from '@nuxt/types'
import buildErrorLink from './errorLink'
import { validateJWTLink } from './client'
import {
  currentPracticeId,
  _currentPracticeBranchId
} from '~/composable/userData'
import fragmentMatcher from '~/generated/django-fragment-matcher.json'
import { UseContextReturn } from '~/plugins/auth'
import { i18nLocale } from '~/composable/scopedI18n'

export const DJANGO_URL = process.env.DJANGO_SERVICE_ADDRESS

const GQL_URL =
  process.env.NUXT_ENV_DJANGO_URL || 'http://localhost:8000/graphql/'

const uploadLink = createUploadLink({
  uri: GQL_URL
})

const authLink = new ApolloLink((operation, forward) => {
  const ctx = operation.getContext()

  operation.setContext(() => {
    const additionalHeaders: Record<string, string> = {
      'x-transaction-id': uuidv4(),
      'x-preffered-language': i18nLocale.value
    }

    if (currentPracticeId.value) {
      additionalHeaders['X-Hasura-Practice-Id'] = String(
        currentPracticeId.value
      )
      additionalHeaders['X-Hasura-Practice-Branch-Id'] = String(
        _currentPracticeBranchId.value
      )
    }
    return {
      headers: {
        ...ctx.headers,
        ...additionalHeaders
      }
    }
  })
  return forward(operation)
})

// Cache implementation
export const cache = new InMemoryCache({
  possibleTypes: fragmentMatcher.possibleTypes
}) as unknown as ApolloCache<NormalizedCache>

// Create the apollo client
export const buildDjangoApolloClient = (context: Context | UseContextReturn) =>
  new ApolloClient({
    link: ApolloLink.from([
      validateJWTLink(context),
      authLink,
      new SentryLink({
        setTransaction: true,
        setFingerprint: true,
        attachBreadcrumbs: {
          includeQuery: true,
          includeVariables: true,
          includeError: true,
          includeContext: ['headers']
        }
      }),
      buildErrorLink(context),
      uploadLink as unknown as ApolloLink
    ]),
    cache,
    connectToDevTools: true
  })
