import { computed, useContext, ref, watch } from '@nuxtjs/composition-api'
import type { Middleware } from '@nuxt/types'
import { useApolloClient } from '@vue/apollo-composable'
import { useScopedI18n } from './scopedI18n'
import { useToasts } from './toasts'
import { useUserPracticesQuery } from '~/generated/graphql'
import { Practice } from '~/types/Practice'
import { EmployeeByUserId } from '~/types/Employee'
import { getNumberIdFromLocalStorage } from '~/utils/getIdFromLocalStorage'
import {
  Avatar,
  Employee,
  GetEmployeeList,
  GetRoomsList,
  GetServicesList,
  useDeleteUserAvatarMutation,
  useGetEmployeeByUserIdQuery,
  useGetPracticeOwnerQuery,
  useGetUserAvatarQuery,
  useSetUserAvatarMutation
} from '~/generated/graphqlDjango'

export enum Role {
  Anonymous = 'anonymous',
  Employee = 'emp',
  Reception = 'rec',
  Finance = 'fin',
  Technical = 'tech',
  Admin = 'adm'
}

export const userRole = ref(Role.Anonymous)
export const currentEmployee = ref<EmployeeByUserId | null>(null)
export const practiceOwner = ref<Employee | null>(null)

export const currentUserRoles = computed<Role[]>(
  () => currentEmployee.value?.roles.map((role) => role.slug as Role) ?? []
)

export const hasCurrentUserEmployeeRole = computed<boolean>(() =>
  currentUserRoles.value.some((r) => [Role.Employee].includes(r))
)

export const isCurrentEmployeeAdminOrReception = computed<boolean>(() =>
  currentUserRoles.value.some((r) => [Role.Admin, Role.Reception].includes(r))
)

export const isCurrentEmployeeAdminOrFinance = computed<boolean>(() =>
  currentUserRoles.value.some((r) => [Role.Admin, Role.Finance].includes(r))
)

export const isCurrentEmployeePrivileged = computed<boolean>(() =>
  currentUserRoles.value.some((r) =>
    [Role.Admin, Role.Finance, Role.Reception].includes(r)
  )
)

export const hasRoles = (roles: Role[] | Role) => {
  if (!Array.isArray(roles)) roles = [roles]

  return roles.some((role) => currentUserRoles.value.includes(role))
}

export const isSelf = (id: number): boolean =>
  Boolean(currentEmployee.value && id === currentEmployee.value.id)

const SELF = 'Self' as const
type Self = typeof SELF
type RoleSelf = Role | Self

// for Role.Self usage the _id parameter has to be present in path
export const rolesMiddleware =
  (roles: RoleSelf[] | RoleSelf, idParam = 'id'): Middleware =>
  ({ redirect, from, params }) => {
    if (!Array.isArray(roles)) roles = [roles]

    const hasSelf = roles.includes(SELF)

    // undefined & '' will resolve to NaN so later on check fails
    const id = parseInt(params[idParam])

    const hasPermission = hasRoles(roles.filter((r) => r !== SELF) as Role[])

    if (!hasPermission && hasSelf && !isSelf(id)) {
      redirect(from.path || '/calendar')
    }
  }

const getCurrentPracticeIdFromLocalStorage = (): number | null =>
  getNumberIdFromLocalStorage('currentPracticeId')

const getCurrentPracticeBranchIdFromLocalStorage = (): number | null =>
  getNumberIdFromLocalStorage('currentPracticeBranchId')

export const currentPracticeId = ref<number | null>(
  getCurrentPracticeIdFromLocalStorage()
)

export const _currentPracticeBranchId = ref<number | null>(
  getCurrentPracticeBranchIdFromLocalStorage()
)

export const usePracticeOwner = () => {
  const { onResult } = useGetPracticeOwnerQuery({
    fetchPolicy: 'cache-first',
    clientId: 'django',
    enabled: isCurrentEmployeeAdminOrReception.value
  })

  onResult((result) => {
    practiceOwner.value = (result?.data?.practiceOwner as Employee) ?? null
  })

  return {
    admin: practiceOwner
  }
}

export const useCurrentEmployee = () => {
  const { $auth } = useContext()

  const user = $auth.$storage.getUniversal('user') as Record<string, unknown>

  const { onResult, refetch } = useGetEmployeeByUserIdQuery(
    {
      userId: user?.sub as string
    },
    { fetchPolicy: 'cache-first', clientId: 'django' }
  )

  const refetchCurrentEmployee = () => refetch({ userId: user?.sub as string })

  onResult((result) => {
    currentEmployee.value = result?.data?.getEmployeeByUserId ?? null
  })

  return {
    employee: currentEmployee,
    refetchCurrentEmployee,
    user
  }
}

export const useUser = () => ({
  user: useContext().$auth.$storage.getUniversal('user') as Record<
    string,
    unknown
  >
})

const practices = ref<Practice[]>([])
const refetchPractices = ref<() => void>(() => null)
const userAvatar = ref<Avatar | null>(null)
const refetchAvatar = ref<() => Promise<void>>()

export const useUserAvatar = () => {
  const getUserAvatar = () => {
    const { refetch, onResult } = useGetUserAvatarQuery({
      clientId: 'django'
    })
    refetchAvatar.value = async () => {
      await refetch()
    }
    onResult((result) => {
      userAvatar.value = result.data.getUserAvatar ?? null
    })
  }

  const { mutate: setUserAvatarMutation } = useSetUserAvatarMutation({
    clientId: 'django'
  })
  const { mutate: deleteUserAvatarMutation } = useDeleteUserAvatarMutation({
    clientId: 'django'
  })

  const { showSuccess } = useToasts()
  const { ts } = useScopedI18n('toasts')

  const loadingAvatar = ref(false)

  const setUserAvatar = async (file: File) => {
    try {
      loadingAvatar.value = true
      await setUserAvatarMutation({ payload: { file } })
      await refetchAvatar.value?.()
    } finally {
      loadingAvatar.value = false
      showSuccess(ts('avatarSet'))
    }
  }

  const deleteUserAvatar = async () => {
    try {
      loadingAvatar.value = true
      await deleteUserAvatarMutation()
      userAvatar.value = null
    } finally {
      loadingAvatar.value = false
      showSuccess(ts('avatarDeleted'))
    }
  }

  return {
    getUserAvatar,
    setUserAvatar,
    deleteUserAvatar,
    loadingAvatar,
    avatarContentType: computed(() => userAvatar.value?.contentType),
    avatarSrc: computed(() => userAvatar.value?.url),
    allowedAvatarMimetypes: 'image/jpeg, image/png, image/gif'
  }
}

export const useUserData = () => {
  const getUserPractices = () => {
    const { refetch, onResult } = useUserPracticesQuery({
      fetchPolicy: 'cache-and-network'
    })
    refetchPractices.value = () => refetch()

    watch(
      currentEmployee,
      () => {
        if (currentEmployee.value) {
          setCurrentPractice(currentEmployee.value.practiceId)
          setCurrentPracticeBranch(currentEmployee.value?.practiceBranchId)
        }
      },
      { deep: true }
    )

    onResult((result) => {
      // in case of no X-Hasura-Practice-Id provided during authorization
      // django falls back to the first one of alphabetically sorted practices
      practices.value = result.data?.practices || []
    })
  }

  const { mutate: setUserAvatarMutation } = useSetUserAvatarMutation({
    clientId: 'django'
  })

  const setUserAvatar = (file: File) =>
    setUserAvatarMutation({ payload: { file } })

  const currentPractice = computed(() => {
    if (!currentPracticeId.value) return

    return practices.value.find((p) => p.id === Number(currentPracticeId.value))
  })

  const setCurrentPractice = (practiceId: number) => {
    currentPracticeId.value = practiceId

    if (practiceId)
      window.localStorage.setItem('currentPracticeId', String(practiceId))
  }

  const currentPracticeBranches = computed(
    () => currentPractice.value?.practice_branches ?? []
  )

  const canSeeServicePrice = computed(
    () =>
      isCurrentEmployeePrivileged.value ||
      !currentPractice.value?.services_per_therapist
  )

  const getCurrentPracticeBranchId = () => {
    const availableBranches = currentPractice.value?.practice_branches ?? []

    const isCurrentBranchAvailable = availableBranches.some(
      (b) => b.id === _currentPracticeBranchId.value
    )

    if (isCurrentBranchAvailable) return _currentPracticeBranchId.value

    return currentEmployee.value?.practiceBranchId ?? 0
  }

  const setCurrentPracticeBranch = (branchId: number | null) => {
    if (!branchId) return
    _currentPracticeBranchId.value = branchId
    localStorage.setItem('currentPracticeBranchId', `${branchId}`)
  }

  const currentPracticeBranchId = computed<number | null>({
    get: getCurrentPracticeBranchId,
    set: setCurrentPracticeBranch
  })

  const { client: apollo } = useApolloClient('django')

  watch(currentPracticeBranchId, (practiceBranchId, oldId) => {
    if (!oldId && practiceBranchId) {
      ;[GetServicesList, GetRoomsList, GetEmployeeList].forEach((query) =>
        apollo.query({
          query,
          fetchPolicy: 'network-only'
        })
      )
    }
  })

  return {
    practices,
    userRole,
    currentPracticeId,
    currentPractice,
    currentPracticeBranches,
    currentPracticeBranchId,
    setCurrentPractice,
    setCurrentPracticeBranch,
    refetch: refetchPractices,
    getUserPractices,
    setUserAvatar,
    currentEmployee,
    canSeeServicePrice
  }
}
