import toastr from 'toastr'
import { ArrayElement } from '~/types/ArrayElement'

const toastTypes = ['success', 'info', 'warning', 'error'] as const

export type ToastType = typeof toastTypes[number]

export const useToasts = (options?: Partial<ToastrOptions>) => {
  const toastrOptions: ToastrOptions = {
    positionClass: 'toast-bottom-right',
    toastClass: 'toast',
    showDuration: 300,
    preventDuplicates: true,
    ...options
  }

  const getToastrMethod =
    (type: ArrayElement<typeof toastTypes>) =>
    (message = '', options?: ToastrOptions & { errorInDevMode: boolean }) => {
      toastr[type]('', message, { ...toastrOptions, ...options })
    }

  const [showSuccess, showInfo, showWarning, showError] =
    toastTypes.map(getToastrMethod)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const wrapper = <T extends (...args: any[]) => Promise<unknown>>(
    fn: T,
    type: ToastType,
    message: string
  ) => {
    return async (...args: Parameters<T>) => {
      const result = await fn(...args)
      getToastrMethod(type)(message)
      return result
    }
  }

  return {
    showSuccess,
    showError,
    showWarning,
    showInfo,
    getToastrMethod,
    wrapper
  }
}
